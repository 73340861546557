//#region IMPORTS
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { required, min, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import PhoneNumber from "awesome-phonenumber";
import gql from "graphql-tag";
//#endregion

//#region Validation rules
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "This {_field_} is required"
});

extend("min", {
  ...min,
  message: "{_field_} must be at least 1 character"
});

extend("max", {
  ...max,
  message: "{_field_} has a maximum of 160 characters"
});

extend("phone", {
  params: ["prefix"],
  message(fieldName, schema) {
    if (schema._prefix_.name)
      return `${fieldName} is not a valid number in ${schema._prefix_.name}`;
    else return "Please select a prefix";
  },
  validate(value, { prefix }) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value, prefix.code);
      resolve({ valid: phone.isValid() });
    });
  }
});
//#endregion

export default {
  components: { ValidationProvider, ValidationObserver },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "SMS" }]);
  },
  data() {
    return {
      countryCodes: require("./countryCodes.json"),
      sms: {
        number: "",
        message: "",
        countryInfo: ""
      }
    };
  },
  methods: {
    sendMessage() {
      let phone = new PhoneNumber(this.sms.number, this.sms.countryInfo.code);
      let phoneNumber = phone.getNumber("significant");
      const smsInput = {
        message: this.sms.message,
        phone_number: phoneNumber,
        country_code: this.sms.countryInfo.code
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($smsInput: SmsInput!) {
              sendSms(sms: $smsInput) {
                status
              }
            }
          `,
          variables: {
            smsInput: smsInput
          }
        })
        .finally(() => {
          this.sms.number = this.sms.message = this.sms.countryInfo = "";
          this.$refs.sendSms.reset();
        });
    }
  }
};
